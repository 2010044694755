import { storeToRefs } from 'pinia'
import { useWebAppNavigation } from 'vue-tg'

import { useControllerStore } from '@/stores/controller'

export const setInvoice = () => {
  const { setup } = storeToRefs(useControllerStore())
  const { openInvoice } = useWebAppNavigation()

  openInvoice(setup.value.data.invoice.result, (status) => {
    if (status === 'paid') {
      setup.value.locale.message = setup.value.data.message
    }
  })
}
