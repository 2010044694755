<template>
  <Main>
    <ButtonCloseApp />
  </Main>
</template>

<script lang="ts" setup>
  import { storeToRefs } from 'pinia'
  import { useControllerStore } from '@/stores/controller'
  const { setup } = storeToRefs(useControllerStore())

  const { setInitControllerData } = useControllerStore()

  const props = defineProps<{
    controller: string;
  }>()

  setInitControllerData(props);
</script>

<style lang="scss">
</style>
