<template>
  <div class="error">
    <Message v-if="setup.locale.message" :setup />
  </div>
</template>

<script lang="ts" setup>
  import { storeToRefs } from 'pinia'
  import { useControllerStore } from '@/stores/controller'
  const { setup } = storeToRefs(useControllerStore())

  const props = defineProps<{
    controller: string;
    locale: object;
    base_locale: object;
  }>()

  Object.assign(setup.value, props)
</script>

<style scoped lang="scss">
</style>
