console.log('Vite ⚡️ Rails')

import { createApp, DefineComponent, h } from 'vue';
import { createInertiaApp, Head, Link } from '@inertiajs/vue3';
import pinia from '@/stores/index'
import VueSmoothScroll from 'vue3-smooth-scroll'

import '~/styles/stylesheet.scss';

createInertiaApp({
  resolve: name => {
    const pages = import.meta.glob('../view/pages/**/*.vue', { eager: true });
    const page = pages[`../view/pages/${name}.vue`];

    if (!page) {
      throw new Error(`Page not found: ${name}`);
    }

    return page as unknown as { default: DefineComponent };
  },
  setup({ el, App, props, plugin }) {
    const app = createApp({ render: () => h(App, props) });

    app.use(plugin);
    app.use(pinia);
    app.use(VueSmoothScroll);
    app.component("iHead", Head);
    app.component("iLink", Link);

    app.mount(el);
  },
});
