import { useWebAppMainButton, useWebAppHapticFeedback } from 'vue-tg';
const { showMainButton, hideMainButton, setMainButtonParams } = useWebAppMainButton()
const { notificationOccurred } = useWebAppHapticFeedback()

// TODO: split a notification and copy to a buffer (maybe create a new entity for a notification)
export const copyCommandTextToBuffer = (event: MouseEvent, copyText: string) => {
    const target = event.target as HTMLElement;

    if (target.nodeName !== 'CODE' || target.classList.contains('no-copy')) {
        return;
    }

    const formatText = (text: string): string => {
        text = text.replace(/\n/g, ' ').replace(/\s+/g, ' ');
        if (text.length > 12) {
            text = `${text.slice(0, 12).trim()}...`;
        }
        return text;
    }

    const text = (target.textContent) ? target.textContent.trim(): '';
    const buttonText = formatText(text);

    navigator.clipboard.writeText(text)
    notificationOccurred('success')
    showMainButton()
    setMainButtonParams({
        text: `${copyText} ${buttonText}`,
        color: '#000',
        text_color: '#fff',
    })

    setTimeout(() => hideMainButton(),1000)
}
