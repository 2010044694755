import { useWebApp, useWebAppTheme } from 'vue-tg';

const { initData, initDataUnsafe } = useWebApp()
const { colorScheme } = useWebAppTheme()

export const setThemeType = () => {
  document.body.dataset.theme = (initDataUnsafe?.user) ? colorScheme.value : 'dark';
}

export const setCustomColorTheme = () => {
  if (!initData) {
    const customStyles = `
      --tg-theme-destructive-text-color: #ff453a; 
      --tg-theme-text-color: #ffffff; 
      --tg-theme-hint-color: #ffffff; 
      --tg-theme-section-bg-color: #282828; 
      --tg-theme-secondary-bg-color: #1c1c1c; 
      --tg-color-scheme: dark; 
      --tg-theme-bg-color: #282828; 
      --tg-theme-header-bg-color: #1c1c1c; 
      --tg-theme-accent-text-color: #007aff; 
      --tg-theme-button-color: #007aff; 
      --tg-theme-button-text-color: #ffffff; 
      --tg-theme-link-color: #007aff; 
      --tg-theme-subtitle-text-color: #ffffff; 
      --tg-theme-section-header-text-color: #e5e5e5; 
      --tg-viewport-height: 100vh; 
      --tg-viewport-stable-height: 100vh;
    `;

    document.documentElement.setAttribute('style', customStyles);
  }
}

export const setTemplateLanguage = (language_code: string) => {
  document.documentElement.setAttribute('lang', (language_code || 'en'));
}
