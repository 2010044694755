<template>
  <div>
    <div v-html="locale.prime_desc"></div>
    <Accordion
      :items="locale.benefits"
    />
    <div v-html="locale.price_desc"></div>
    <Accordion
      :items="locale.turbos"
    />
    <div v-html="locale.turbo_price"></div>
  </div>
</template>

<script setup lang="ts">
  import { type Description } from '@/types/description'

  const props = defineProps<{
    description: {
      [key: string]: Description;
    }
  }>();

  const { description: locale } = props;
</script>

<style scoped>
</style>
