<template>
  <div class="message">
    <img
      :src="getImageUrl('logo.webp')"
      width="200"
      height="200"
    >
    <h1 v-if="props.setup.locale">{{ props.setup.locale.status }}</h1>
    <h3 v-html="message"></h3>
    <ButtonCloseApp />
  </div>
</template>

<script setup lang="ts">
  import ButtonCloseApp from '@/view/components/ui/buttons/ButtonCloseApp.vue'
  import { getImageUrl } from '@/composables/images';

  import { Setup } from '@/types/controller'

  const props = defineProps<{
    setup: Setup 
  }>()

  const message = computed(() => (props.setup.locale.error) ? props.setup.base_locale.error_message : props.setup.locale.message);
</script>

<style scoped lang="scss">
  .message {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100dvh - 2rem);
    text-align: center;
    img {
      height: 200px;
      width: auto;
    }
    h1 {
      margin-bottom: 0;
      font-size: 44px;
    }
  }
</style>
