<template>
  <div>
    <h3>{{ locale.title }}</h3>
    <Accordion
      :items="locale.commands"
    />
  </div>
</template>

<script setup lang="ts">
  import { type Description } from '@/types/description'

  const props = defineProps<{
    description: {
      [key: string]: Description;
    }
  }>();

  const { description: locale } = props;
</script>

<style scoped>
</style>
